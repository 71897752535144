import { useEffect, useRef, useState } from 'react'
import { useHistory } from './useHistoryApi'

interface IMobileScrollHandlerProps {
  targetSectionHeight: number
  isEnabled: boolean
  options?: {
    hoverSection?: HTMLElement | null // show element on hover if exist
    showOnScrollUp?: boolean
  }
}

export const useMobileScrollHandler = ({
  targetSectionHeight,
  isEnabled,
  options,
}: IMobileScrollHandlerProps) => {
  const [isSectionVisible, setSectionVisible] = useState(true)
  const lastScrollTop = useRef(0)
  const isMouseOver = useRef(false)

  const history = useHistory()
  const { hoverSection, showOnScrollUp } = options || {}

  useEffect(() => {
    // We should reset header state when redirected/navigate to new page that may not has scroll at all.
    setSectionVisible(true)
  }, [history?.location?.pathname])

  useEffect(() => {
    if (isEnabled) {
      const isScrollTop = (scrollTop: number) => {
        if (!showOnScrollUp) return false
        return scrollTop < lastScrollTop.current
      }

      /*
       * Function to determine if the user has entered the "bouncing" scroll zone on MacOS / iOS.
       * Bouncing zone is an area that allows the user to scroll content beyond its end, creating a "bounce" effect.
       */
      const isBouncingScrollZone = (scrollEl: Window) => {
        const scrollTop = scrollEl.scrollY
        const scrollHeight = document.documentElement.scrollHeight
        const offsetHeight = scrollEl.innerHeight

        return scrollTop >= scrollHeight - offsetHeight - 10
      }

      const scrollEl = window

      const scrollHandler = () => {
        if (isBouncingScrollZone(scrollEl)) {
          return
        }

        const scrollTop = scrollEl.scrollY

        let shouldShowSection = false

        if (targetSectionHeight) {
          shouldShowSection = targetSectionHeight > scrollTop || isScrollTop(scrollTop)
        }

        lastScrollTop.current = scrollTop

        shouldShowSection !== isSectionVisible && setSectionVisible(shouldShowSection)
      }

      const handleMouseOver = () => {
        if (!isMouseOver.current) {
          isMouseOver.current = true
          setSectionVisible(true)
        }
      }

      const handleMouseOut = () => {
        if (isMouseOver.current) {
          isMouseOver.current = false
        }
      }

      scrollEl.addEventListener('scroll', scrollHandler)

      if (hoverSection) {
        hoverSection.addEventListener('mouseover', handleMouseOver)
        hoverSection.addEventListener('mouseout', handleMouseOut)
      }

      return () => {
        scrollEl.removeEventListener('scroll', scrollHandler)

        if (hoverSection) {
          hoverSection.removeEventListener('mouseover', handleMouseOver)
          hoverSection.removeEventListener('mouseout', handleMouseOver)
        }
      }
    } else {
      setSectionVisible(true)
    }
  }, [targetSectionHeight, isSectionVisible, isEnabled, hoverSection, showOnScrollUp])

  return isSectionVisible
}
